//ant select
.ant-select-selector {
  display: flex;
}

.RangePicker {
  width: 100%;
}

// ant tree
.ant-tree-list {
  .ant-tree-node-selected {
    .ant-tree-title {
      color: $colorPrimary;
    }
  }
  .ant-tree-treenode {
    .ant-tree-indent-unit {
      &::before {
        border-right-style: dashed;
      }
    }
    .ant-tree-switcher {
      color: $colorPrimary;
      &-leaf-line {
        &::after {
          border-bottom-style: dashed;
        }
        &::before {
          border-right-style: dashed;
        }
      }
    }
  }
}
