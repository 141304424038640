@charset "UTF-8";

:root {
  --color-primary: #e54e4b;
  --color-primary-rgb: 229, 78, 75;
  --color-primary-light: #ff6b6b;
  --color-primary-light-rgb: 255, 107, 107;
  --color-primary-dark: #f05a58;
  --color-primary-dark-rgb: 240, 90, 88;
  --color-primary: #e54e4b;
  --color-primary-rgb: 229, 78, 75;
  --color-sub-1: #ff3838;
  --color-sub-2: #ff8585;
  --color-sub-3: #f00;
  --color-blue-light: #00aee8;
  --color-rmb: #e54e4b;
}

// switch
//-----------------------------------------------------
$borderBoxSwitch: true !default; // true为*{box-sizing:border-box;}

// font相关
//-----------------------------------------------------
$font-size: 14px !default;
$pad-font-size: 12px !default;
$font-line-height: 1.5 !default;
$font-family: San Francisco UI, Helvetica Neue, Arial, DIN Alternate, PingFang SC, Hiragino Sans GB, Micrisoft YaHei !default;

// color
$colorTextDark: #333 !default;
$primary-color: #e54e4b !default; // 主色
$primary-color-light: #87abdc !default; // hover
$secondary-color: #3875c6 !default; // 辅色 ｜ 链接
$contrast-color: #04ba97 !default; // 对比色 | 成功
$warning-color: #f53f3f !default; //警示颜色 ｜ 失败
$default-text-color: #192d51 !default; // 表格文本颜色
$colorBgLight: #f8f8f8 !default;
$update-color: #00aee8 !default; // 操作栏目颜色
$info-color: #598ae3 !default; // 蓝色
$tips-color: #ffa246 !default; //提示颜色
$link-color: #666 $primary-color !default; // 如果只有一个值则滑过的值为这个值的darken 5%，如果有两个值则第二个值为滑过的值
$bg-color: #fff !default;
$bg-contentColor: #f6f7f9; //内容色
$bg-disableColor: #f1f2f6; //禁用色
$bg-icColor: #999fb6; //图标色
$line-color: #e4e8ee; //分割线
$stroke-color: #dadee5; //描边
$placeholder-color: #b3b0ba !default;
$white: white !default;
$black: black !default;
$colorPrimary: #e54e4b !default; //主色
$colorPrimaryRgb:  229, 78, 75 !default; //主色
$colorPrimaryLight: #ff6b6b !default; //辅色
$colorPrimaryLightRgb: 255, 107, 107 !default; //辅色
$colorPrimaryDark: #f05a58 !default;
$colorPrimaryDarkRgb: 240, 90, 88 !default;
$colorTableBtn: #3b77aa !default;
// base values
$marginBase: 20px !default;
$paddingBase: 20px !default;
$paddingContent: 24px !default;
$colorSub1: #ff3838 !default; // 辅色2
$colorSub2: #ff8585 !default; // 辅色2
$colorSub3: #f00 !default; // 辅色2
$colorWarning: #19bfbd !default; //对比色
$colorPrimaryLightRgb: var(--color-primary-light-rgb) !default; //辅色
$colorPurple: #c97196 !default;
$colorContrast: #19bfbd !default;
$colorTableBtn: #3b77aa !default;
$colorDarkBlue: #3b77aa !default;
// text color
$text-color: #333333 !default;
$text-color-dark: #8f959e !default;
$text-color-light: #192d51 !default;
$text-color-gray: #9a9fb4 !default;
$tword-color: #192d51; //正文字体颜色

$text-color-8: rgba($text-color-dark, 0.8);
$text-color-6: rgba($text-color-dark, 0.6);
$text-color-2: rgba($text-color-dark, 0.2);

// border color
$border-color: #ddd !default;

//  border radius
$radiusSmall: 4px !default;
$radiusBase: 6px !default;

// bg color
$page-gray-bg: #f1f2f6 !default;

// 弹窗头部背景颜色
$popup-bg: #f4f6f9;
// form
$inputWidth: 256px !default;

// media
$tablet-width: 1024px;
$laptop-width: 1280px;
$desktop-width: 1920px;

// zIndex
$headerIndex: 100;

$orange: #ff8463 !default;
$yellow: #f8b020 !default;
$red: #f00 !default;
$green: #5cb85c !default;
$greenLight: #77cdb0 !default;
$sliver: #7da0b4 !default;
$purpleLight: #b3becf !default;

$black: #000 !default;
$gray3: #333 !default;
$grayf3: #f3f3f3 !default;
$gray5: #555 !default;
$gray6: #666 !default;
$gray7: #777 !default;
$gray9: #999 !default;
$grayA: #aaa !default;
$grayC: #ccc !default;
$grayD: #ddd !default;
$grayE: #eee !default;
$grayF: #f5f5f5 !default;
$white: #fff !default;

$inputSize: 12px 32px !default; // 默认输入框的左右padding，高度
$inputSizeSm: 10px 28px !default;
$inputSizeLg: 14px 36px !default;
