@charset "UTF-8";
@import "~@/assets/sass/_variables";
// mixin & %
// 既定义了mixin也定义了%，根据需求使用@include或@extend调用
//-----------------------------------------------------
// Center-align a block level element
@mixin center-block {
  margin-left: auto;
  margin-right: auto;
}

%center-block {
  @include center-block;
}

// clearfix
@mixin clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

%clearfix {
  @include clearfix;
}

// ellipsis
@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

%ellipsis {
  @include ellipsis;
}

// ellipsis multi
@mixin ellipsis-lines($lines: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  word-break: break-all;
}

%ellipsis-lines {
  @include ellipsis-lines;
}

%ellipsis-lines-1 {
  @include ellipsis-lines(1);
}

%ellipsis-lines-3 {
  @include ellipsis-lines(3);
}

// word-break
// 用于链接地址强制换行
@mixin word-break {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
}

%word-break {
  @include word-break;
}

// disabled
// 禁用样式，加!important
@mixin disabled($textColor: nth($colorDisabled, 1), $bgColor: nth($colorDisabled, 2), $borderColor: true) {
  background-color: $bgColor !important;
  color: $textColor !important;
  cursor: default !important;
  pointer-events: none !important;
  @if $borderColor {
    border: 1px solid nth($colorDisabled, 3);
  }
}

%disabled {
  @include disabled;
}

// fixed top & bottom
@mixin fixed($pos: 0) {
  position: fixed;
  left: 0;
  right: 0;
  @if $pos==bottom {
    bottom: 0;
  } @else {
    top: $pos;
  }
}

%fixed-top {
  @include fixed;
}

%fixed-bottom {
  @include fixed($pos: bottom);
}

// justify
@mixin justify {
  display: flex;
  justify-content: space-between;
}

%justify {
  @include justify;
}

// flex 等分
@mixin equal-flex($children: li) {
  display: flex;
  $childrenEle: li div p a span strong;
  @if index($childrenEle, $children) {
    #{$children} {
      flex: 1;
    }
  } @else {
    .#{$children} {
      flex: 1;
    }
  }
}

%equal-flex {
  @include equal-flex;
}

@mixin background-gradient($color1, $color2, $horz: left, $Vert: middle) {
  @if $horz==left {
    @if $vert==top {
      background-image: linear-gradient(135deg, $color1, $color2);
    } @else if $vert==middle {
      background-image: linear-gradient(90deg, $color1, $color2);
    } @else if $vert==bottom {
      background-image: linear-gradient(-315deg, $color1, $color2);
    }
  } @else if $horz==middle {
    @if $Vert==top {
      background-image: linear-gradient(90deg, $color2, $color1, $color1, $color2);
    } @else if $Vert==bottom {
      background-image: linear-gradient(0deg, $color2, $color1, $color1, $color2);
    }
  } @else if $horz==right {
    @if $vert==top {
      background-image: linear-gradient(225deg, $color1, $color2);
    } @else if $vert==middle {
      background-image: linear-gradient(270deg, $color1, $color2);
    } @else if $vert==bottom {
      background-image: linear-gradient(315deg, $color1, $color2);
    }
  }
}

// flex center
@mixin center-flex($direction: both) {
  display: flex;
  @if $direction==both {
    justify-content: center;
    align-items: center;
  } @else if $direction==x {
    justify-content: center;
  } @else if $direction==y {
    align-items: center;
  }
}

%center-flex {
  @include center-flex;
}

%center-flex-x {
  @include center-flex(x);
}

%center-flex-y {
  @include center-flex(y);
}

// translate center
@mixin center-translate($direction: both) {
  position: absolute;
  @if $direction==both {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  } @else if $direction==x {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  } @else if $direction==y {
    top: 50%;
    transform: translate3d(0, -50%, 0);
  }
}

%center-translate {
  @include center-translate;
}

%center-translate-x {
  @include center-translate(x);
}

%center-translate-y {
  @include center-translate(y);
}

// triangle 三角箭头
%triangle-basic {
  content: "";
  height: 0;
  width: 0;
  overflow: hidden;
}

@mixin triangle($direction: bottom, $borderWidth: 6px, $borderColor: $gray6) {
  @extend %triangle-basic;
  @if $direction==top {
    border-bottom: $borderWidth solid $borderColor;
    border-left: $borderWidth dashed transparent;
    border-right: $borderWidth dashed transparent;
  } @else if $direction==right {
    border-left: $borderWidth solid $borderColor;
    border-top: $borderWidth dashed transparent;
    border-bottom: $borderWidth dashed transparent;
  } @else if $direction==bottom {
    border-top: $borderWidth solid $borderColor;
    border-left: $borderWidth dashed transparent;
    border-right: $borderWidth dashed transparent;
  } @else if $direction==left {
    border-right: $borderWidth solid $borderColor;
    border-top: $borderWidth dashed transparent;
    border-bottom: $borderWidth dashed transparent;
  }
}

@mixin arrow-triangle($direction: bottom, $borderWidth: 6px, $borderColor: #ccc) {
  position: absolute;
  s,
  i {
    position: absolute;
  }
  s {
    @include triangle($direction, $borderWidth, $borderColor);
  }
  i {
    @include triangle($direction, $borderWidth, $white);
  }
  @if ($direction == left) {
    s {
      left: 0;
    }
    i {
      left: 1px;
    }
  } @else if ($direction == right) {
    s {
      right: 0;
    }
    i {
      right: 1px;
    }
  } @else if ($direction == top) {
    s {
      top: 0;
    }
    i {
      top: 1px;
    }
  } @else if ($direction == bottom) {
    s {
      bottom: 0;
    }
    i {
      bottom: 1px;
    }
  }
}

%triangle-bottom {
  @include triangle;
}

%triangle-top {
  @include triangle($direction: top);
}

//group column
@mixin group-grid($grid: 2, $margin: $marginBase) {
  & {
    > ul {
      margin-left: -$margin / 2;
      margin-right: -$margin / 2;
      @extend %clearfix;

      > li {
        float: left;
        width: 100 / $grid * 1%;
        margin-bottom: $margin;
        padding-left: $margin / 2;
        padding-right: $margin / 2;
      }
    }
  }
}

%group-grid {
  @include group-grid;
}

%group-grid-3 {
  @include group-grid($grid: 3);
}

%group-grid-4 {
  @include group-grid($grid: 4);
}

%group-grid-7 {
  @include group-grid($grid: 7);
}

//Image
%image-style {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@mixin image-responsive($width: 140px, $height: 200px, $children: img) {
  & {
    position: relative;
    //padding-top: $height / $width * 100%;
    #{$children} {
      @extend %image-style;
    }
  }
}

%image-responsive {
  @include image-responsive;
}

%image-responsive1by1 {
  @include image-responsive(100, 100);
}

// px单位转成vw单位
@function px2vw($size: 14px, $width: 1920px) {
  @return $size * 100vw / $width;
}

// px to rem
@function px2rem($value, $fontSizeRoot: $fontSize) {
  @return $value / $fontSizeRoot * 1rem;
}

//font-dpr
@mixin font-dpr($font-size) {
  font-size: $font-size;
  [data-dpr="2"] & {
    font-size: $font-size * 2;
  }
  [data-dpr="3"] & {
    font-size: $font-size * 3;
  }
}

//props-dpr
@mixin props-dpr($props, $values) {
  @if length($props) ==1 {
    @if length($values) ==1 {
      #{$props}: $values;
      [data-dpr="2"] & {
        #{$props}: $values * 2;
      }
      [data-dpr="3"] & {
        #{$props}: $values * 3;
      }
    } @else {
      $valueStr: ();
      @each $value in $values {
        $valueStr: append($valueStr, $value);
      }
      #{$props}: $valueStr;
      [data-dpr="2"] & {
        $valueStr: ();
        @each $value in $values {
          $valueStr: append($valueStr, $value * 2);
        }
        #{$props}: $valueStr;
      }
      [data-dpr="3"] & {
        $valueStr: ();
        @each $value in $values {
          $valueStr: append($valueStr, $value * 3);
        }
        #{$props}: $valueStr;
      }
      //@debug $valueStr;
    }
  } @else {
    @each $prop in $props {
      #{$prop}: nth($values, index($props, $prop));
    }
    [data-dpr="2"] & {
      @each $prop in $props {
        #{$prop}: nth($values, index($props, $prop)) * 2;
      }
    }
    [data-dpr="3"] & {
      @each $prop in $props {
        #{$prop}: nth($values, index($props, $prop)) * 3;
      }
    }
  }
}

//其他占位符
%transition-all {
  transition: all 0.3s ease-in-out;
}

%page-out {
  transition: transform 0.4s cubic-bezier(0.42, 0, 0.58, 1) 0.1s;
}

%page-in {
  transition: transform 0.3s cubic-bezier(0.42, 0, 0.58, 1);
}

//Font size
%f10 {
  font-size: 10px;
}

%f12 {
  font-size: 12px !important;
}

%f14 {
  font-size: 14px !important;
}

%f15 {
  font-size: 15px;
}

%f16 {
  font-size: 16px;
}

%f18 {
  font-size: 18px;
}

%f20 {
  font-size: 20px;
}

%f22 {
  font-size: 22px;
}

%f24 {
  font-size: 24px;
}

%f30 {
  font-size: 30px;
}

//Margin
%mb10 {
  margin-bottom: 10px;
}

%mb20 {
  margin-bottom: 20px;
}

%mb30 {
  margin-bottom: 30px;
}

%mr10 {
  margin-bottom: 10px;
}

%ml10 {
  margin-bottom: 10px;
}

@mixin blur($value) {
  -webkit-filter: blur($value);
  -moz-filter: blur($value);
  -o-filter: blur($value);
  //filter: url(blur.svg#blur);
  //filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');
  //-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  //filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: blur($value);
}

@mixin splitLine($position: right) {
  @if $position == left {
    position: relative;
    &::before {
      @extend %split-line;
      left: 0;
      right: auto;
    }
  }
  @if $position == right {
    &::before {
      @extend %split-line;
      right: 0;
      left: auto;
    }
  }
}

// item 分割线
%split-line {
  content: "";
  position: absolute;
  right: 0;
  width: 1px;
  height: 15px;
  background-color: $grayE;
}

//表单错误提醒
%error-inline {
  .FormItem .error-detail {
    margin-left: 10px;
    font-size: 12px;
    color: $red;
  }
}

%error-block {
  .FormItem .error-detail {
    display: block;
    margin-top: 5px;
    margin-left: 0;
    font-size: 12px;
    color: $red;
  }
}

//日期选择器
%datepicker-sm {
  .DatePicker {
    .date-input-wrap {
      .date-input {
        height: 28px;
        padding: 5px 10px;
        line-height: 18px;
        font-size: 12px;
      }

      .ic {
        width: 28px;
        height: 28px;
        line-height: 28px;
      }
    }
  }
}

%form-control {
  $lineHeight: 20px;
  $height: nth($inputSize, 2);
  $paddingX: nth($inputSize, 1);
  $paddingY: ($height - $lineHeight - 2) / 2;
  display: inline-block;
  height: $height;
  padding: $paddingY $paddingX;
  border: 1px solid $grayD;
  border-radius: $radiusSmall;
  color: $colorTextDark;
  font-size: $fontSize;
  line-height: $lineHeight;
  outline: none;
  vertical-align: middle;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  //@media (max-width: $desktop-width) {
  //  height: 28px;
  //  font-size: $padFontSize;
  //  line-height: 26px;
  //}
  &:focus {
    border-color: rgba($colorPrimary, 0.5);
    /*box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 4px rgba($colorPrimary, 0.6);*/
  }

  &::-moz-placeholder {
    color: $colorPlaceholder;
    opacity: 1;
    //font-size: $padFontSize;
  }

  &:-ms-input-placeholder {
    color: $colorPlaceholder;
    //font-size: $padFontSize;
  }

  &::-webkit-input-placeholder {
    color: $colorPlaceholder;
    //font-size: $padFontSize;
  }

  &.disabled,
  &[disabled] {
    background-color: nth($colorDisabled, 2);
    color: $gray3;
    opacity: 0.6;
  }
}

%input-sm {
  $lineHeight: 18px;
  $height: nth($inputSizeSm, 2);
  $paddingX: nth($inputSizeSm, 1);
  $paddingY: ($height - $lineHeight - 2) / 2;
  height: $height;
  padding: $paddingY $paddingX;
  font-size: $fontSize;
  line-height: $lineHeight;
}

%input-lg {
  $lineHeight: 20px;
  $height: nth($inputSizeLg, 2);
  $paddingX: nth($inputSizeLg, 1);
  $paddingY: ($height - $lineHeight - 2) / 2;
  height: $height;
  padding: $paddingY $paddingX;
  font-size: $fontSize;
  line-height: $lineHeight;
}

%btn {
  $lineHeight: 20px;
  $height: nth($btnSize, 2);
  $paddingX: nth($btnSize, 1);
  $paddingY: ($height - $lineHeight - 2) / 2;
  display: inline-block;
  padding: $paddingY $paddingX;
  margin-bottom: 0;
  font-size: $fontSize;
  font-weight: normal;
  line-height: $lineHeight;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: $radiusSmall;
  outline: none;
  cursor: pointer;
  //@media (max-width: $desktop-width) {
  //  height: 28px;
  //  font-size: $padFontSize;
  //  line-height: 12px;
  //}
  &.disabled,
  &[disabled] {
    cursor: default;
    filter: alpha(opacity=65);
    box-shadow: none;
    opacity: 0.65;
  }
}

%btn-sm {
  $lineHeight: 20px;
  $height: nth($btnSizeSm, 2);
  $paddingX: nth($btnSizeSm, 1);
  $paddingY: ($height - $lineHeight - 2) / 2;
  padding: $paddingY $paddingX;
  font-size: $fontSize;
  line-height: $lineHeight;
}

%btn-lg {
  $lineHeight: 20px;
  $height: nth($btnSizeLg, 2);
  $paddingX: nth($btnSizeLg, 1);
  $paddingY: ($height - $lineHeight - 2) / 2;
  padding: $paddingY $paddingX;
  font-size: 14px;
  line-height: $lineHeight;
}

%btn-default {
  color: $gray3;
  background-color: $colorBg;
  border-color: $grayD;

  &:hover {
    color: $colorPrimary;
    background-color: $colorBg;
    border-color: $colorPrimary;
  }
}

%btn-primary {
  color: $white;
  background-color: $colorPrimary;
  border-color: $colorPrimary;

  &:hover {
    color: $white;
    background-color: lighten($colorPrimary, 5%);
    border-color: lighten($colorPrimary, 5%);
  }
}

%btn-primaryLight {
  color: $white;
  background-color: $colorPrimaryLight;
  border-color: $colorPrimaryLight;

  &:hover {
    color: $white;
    background-color: lighten($colorPrimaryLight, 5%);
    border-color: lighten($colorPrimaryLight, 5%);
  }
}

%btn-primary-transparent {
  color: $colorPrimary;
  background-color: rgba($colorPrimary, 0.1);

  &:hover {
    color: $colorPrimary;
    background-color: darken(rgba($colorPrimary, 0.1), 5%);
  }
}

%btn-primarylight {
  color: $white;
  background-color: $colorPrimaryLight;
  border-color: $colorPrimaryLight;

  &:hover {
    color: $white;
    background-color: lighten($colorPrimaryLight, 5%);
    border-color: lighten($colorPrimaryLight, 5%);
  }
}

%btn-outline-primary {
  color: $colorPrimary;
  background-color: $white;
  border-color: $colorPrimary;

  &:hover {
    color: $white;
    background-color: $colorPrimary;
  }
}

%btn-outline-primaryLight {
  color: $colorPrimaryLight;
  background-color: $white;
  border-color: $colorPrimaryLight;

  &:hover {
    color: $white;
    background-color: $colorPrimaryLight;
  }
}

%btn-gradient {
  border: 1px solid $grayD;
  color: $gray3;
  background: linear-gradient(to bottom, $white, #f6f6f6);

  &:hover {
    color: $gray3;
    background: linear-gradient(to bottom, $white, darken(#f6f6f6, 5%));
  }
}

%btn-success {
  color: #fff;
  background-color: #18c0bc;
  border-color: #18c0bc;

  &:hover {
    color: #fff;
    background-color: lighten(#18c0bc, 5%);
    border-color: lighten(#18c0bc, 5%);
  }
}

%btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;

  &:hover {
    color: #fff;
    background-color: #31b0d5;
    border-color: #269abc;
  }
}

%btn-table {
  color: $colorTableBtn;
  position: relative;
  padding: 5px;
  background-color: transparent;
  border: none;
  vertical-align: middle;
  display: inline-block;
  line-height: 18px;
  cursor: pointer;
  font-size: 14px;

  &:focus {
    outline: none;
  }

  &:hover {
    color: darken($colorTableBtn, 5%);
  }

  &:before {
    content: "";
    position: absolute;
    width: 1px;
    height: 10px;
    right: 0;
    top: 10px;
    background-color: $colorTableBtn;
  }

  &:last-child {
    padding-right: 0;

    &:before {
      display: none;
    }
  }

  &:first-child {
    padding-left: 0;
  }
}

%btn-table-oper {
  color: $gray6;
  padding: 5px;
  background-color: transparent;
  border: none;
  vertical-align: middle;
  display: inline-block;
  line-height: 18px;
  word-break: keep-all;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    color: darken($colorTableBtn, 5%);
  }
}

%btn-warning {
  color: $white;
  background-color: $colorWarning;
  border-color: $colorWarning;

  &:hover {
    color: $white;
    background-color: darken($colorWarning, 5%);
    border-color: darken($colorWarning, 5%);
  }
}

%btn-purple {
  color: $white;
  background-color: $colorPurple;
  border-color: $colorPurple;

  &:hover {
    color: $white;
    background-color: darken($colorPurple, 5%);
    border-color: darken($colorPurple, 5%);
  }
}

%btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;

  &:hover {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925;
  }
}

%btn-link {
  font-weight: normal;
  color: $gray6;
  border-radius: 0;

  &:hover {
    color: #23527c;
    background-color: transparent;
  }
}

%btn-white {
  color: $gray3;
  border: 1px solid $grayD;
  background-color: $white;

  &:hover {
    border: 1px solid $colorPrimaryLight;
    color: $colorPrimaryLight;
  }
}

%btn-grayE {
  color: $gray6;
  border: 1px solid $colorBorder;
  background-color: $grayE;

  &:hover {
    border: 1px solid $colorPrimary;
    color: $colorPrimary;
    background-color: rgba($colorPrimaryLight, 0.1);
  }
}

%btn-orange {
  color: $white;
  background-color: $orange;

  &:hover {
    background-color: darken($orange, 4%);
  }
}

%btn-lightSteelBlue {
  color: $white;
  background-color: #9aaec1;

  &:hover {
    background-color: darken(#9aaec1, 4%);
  }
}

%btn-darkBlue {
  color: $white;
  background-color: #2f4857;

  &:hover {
    background-color: darken(#2f4857, 4%);
  }
}

%btn-gray {
  color: $gray3;
  background-color: $colorBgLight;
  border-color: $colorBorder;

  &:hover {
    background-color: $grayf3;
  }
}

%btn-gradual-gray {
  @extend %btn-gradient;
}

%btn-purpleLight {
  color: $white;
  background-color: $purpleLight;
  border-color: $purpleLight;

  &:hover {
    color: $white;
    background-color: darken($purpleLight, 5%);
    border-color: darken($purpleLight, 5%);
  }
}

%btn-blue {
  color: $white;
  background-color: #44c1de;
  border-color: #44c1de;

  &:hover {
    color: $white;
    background-color: darken(#44c1de, 5%);
    border-color: darken(#44c1de, 5%);
  }
}

%btn-contrast {
  color: $white;
  background-color: $colorContrast;
  border-color: $colorContrast;

  &:hover {
    color: $white;
    background-color: darken($colorContrast, 5%);
    border-color: darken($colorContrast, 5%);
  }
}

%btn-not-allowed {
  color: $white;
  background-color: $grayC;
  border-color: $grayC;
  cursor: not-allowed;
}

%btn-underline-default {
  padding: 5px 0;
  color: $gray6;
  text-decoration: underline;
  cursor: pointer;

  .ic {
    margin-right: 6px;
    color: $gray9;
  }

  &:hover {
    color: darken($gray6, 5%);

    .ic {
      color: darken($gray9, 5%);
    }
  }
}

%btn-underline-primary {
  padding: 5px 0;
  color: $colorPrimary;
  text-decoration: underline;
  cursor: pointer;

  .ic {
    margin-right: 6px;
  }

  &:hover {
    color: darken($colorPrimary, 5%);
  }
}

%btn-underline-lightPrimary {
  padding: 5px 0;
  color: $colorPrimaryLight;
  text-decoration: underline;
  cursor: pointer;

  .ic {
    margin-right: 6px;
  }

  &:hover {
    color: darken($colorPrimaryLight, 5%);
  }
}

%btn-underline-constract {
  padding: 5px 0;
  color: $colorContrast;
  text-decoration: underline;
  cursor: pointer;

  .ic {
    margin-right: 6px;
  }

  &:hover {
    color: darken($colorContrast, 5%);
  }
}

%btn-underline-darkblue {
  padding: 5px 0;
  color: $colorDarkBlue;
  text-decoration: underline;
  cursor: pointer;

  .ic {
    margin-right: 6px;
  }

  &:hover {
    color: darken($colorDarkBlue, 5%);
  }
}

@mixin grid($margin: $marginSmall, $isMarginBottom: true) {
  $marginHalf: $margin / 2;
  .row {
    margin-left: -$marginHalf;
    margin-right: -$marginHalf;

    [class^="col-"] {
      @if $isMarginBottom {
        margin-bottom: $margin;
      }
      padding-left: $marginHalf;
      padding-right: $marginHalf;
    }
  }
}

%grid-sm {
  @include grid(20px, false);
}

%grid-lg {
  .row {
    margin-left: -20px;
    margin-right: -20px;

    [class^="col-"] {
      margin-bottom: 40px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

%grid-none {
  .row {
    margin-left: 0;
    margin-right: 0;

    [class^="col-"] {
      margin-bottom: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

%inline-block {
  display: inline-block;
  vertical-align: middle;
}

// px单位转成em单位
@function units($number) {
  @return $number / ($number * 0 + 1);
}

@function px2em($size, $context: 16px) {
  @if not unitless($size) {
    $size: units($size);
  }
  @if not unitless($context) {
    $context: units($context);
  }
  @return ($size / $context) * 1em;
}

//媒体查询
@mixin tablet {
  @media screen and (max-device-width: #{$tablet-width}) {
    @content;
  }
}

@mixin laptop {
  @media (max-device-width: #{$laptop-width}) {
    @content;
  }
}

@mixin desktop {
  @media (max-device-width: #{$desktop-width}) {
    @content;
  }
}

@function px2vw($size) {
  @return $size * 0.5;
}


//媒体查询
@mixin tablet {
  @media (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin sm-desktop {
  @media (max-width: #{$smDesktop-width}) {
    @content;
  }
}

%ic-fix {
  position: relative;
  top: -1px;
}

//Radio
%radio-lg {
  .radio-label {
    width: 16px;
    height: 16px;

    .radio-symbol {
      width: 8px;
      height: 8px;
      margin-left: -4px;
      margin-top: -4px;
    }
  }
}

//阴影
//整屏页面的效果
%page-box {
  background-color: $white;
  //box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  //border: solid 1px $grayE;
}

//页面块状的效果
%block-box {
  border-radius: 2px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);
  background-color: $white;
}

//四分之一环
@mixin quarter-ring($width, $borderWidth, $preColor, $nextColor) {
  $newWidth: $width;
  &:before,
  &:after {
    position: absolute;
    content: "";
    display: block;
    width: $newWidth;
    height: $newWidth;
    background-color: $preColor;
    border-radius: $newWidth 0 0 0;
  }
  &:after {
    margin-left: $borderWidth;
    margin-top: $borderWidth;
    background-color: $nextColor;
  }
}

//扬声器播放效果
@mixin loudspeaker($color, $bgColor: $white) {
  position: absolute;
  transform: rotate(135deg);
  @keyframes playing-audio-qa {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .ring {
    position: absolute;

    &:nth-child(1) {
      @include quarter-ring(16px, 2px, $color, $bgColor);
    }

    &:nth-child(2) {
      margin-left: 6px;
      margin-top: 5px;
      @include quarter-ring(12px, 2px, $color, $bgColor);
    }

    &:nth-child(3) {
      margin-left: 11px;
      margin-top: 11px;
      @include quarter-ring(7px, 2px, $color, $bgColor);
    }

    &:before {
      background-color: $color;
    }
  }
  &.playing {
    .ring {
      &:nth-child(1) {
        animation: playing-audio-qa 0.8s 0.3s infinite;
      }

      &:nth-child(2) {
        animation: playing-audio-qa 0.8s 0s infinite;
      }

      &:nth-child(3) {
      }
    }
  }
}


%form-control {
  $lineHeight: 20px;
  $height: nth($inputSize, 2);
  $paddingX: nth($inputSize, 1);
  $paddingY: ($height - $lineHeight - 2) / 2;
  display: inline-block;
  height: $height;
  padding: $paddingY $paddingX;
  border: 1px solid $grayD;
  border-radius: $radiusSmall;
  color: $colorTextDark;
  font-size: $fontSize;
  line-height: $lineHeight;
  outline: none;
  vertical-align: middle;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  //@media (max-width: $desktop-width) {
  //  height: 28px;
  //  font-size: $padFontSize;
  //  line-height: 26px;
  //}
  &:focus {
    border-color: rgba(229, 78, 75, 0.5);
    /*box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 4px rgba($colorPrimaryRgb, 0.6);*/
  }

  &::-moz-placeholder {
    color: $colorPlaceholder;
    opacity: 1;
    //font-size: $padFontSize;
  }

  &:-ms-input-placeholder {
    color: $colorPlaceholder;
    //font-size: $padFontSize;
  }

  &::-webkit-input-placeholder {
    color: $colorPlaceholder;
    //font-size: $padFontSize;
  }

  &.disabled,
  &[disabled] {
    background-color: nth($colorDisabled, 2);
    color: $gray3;
    opacity: 0.6;
  }
}


@mixin input-width($width) {
  .wrapper-box {
    input {
      width: $width;
    }
  }
}
