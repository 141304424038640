@charset "UTF-8";
@import "variables";

/** text color **/
.text-default {
  color: $text-color-light;
}

.text-primary {
  color: $primary-color;
}

.update-primary {
  color: $update-color;
  cursor: pointer;
}

.text-gray {
  color: $text-color-gray;
}

.text-hover {
  color: $text-color;
  &:hover {
    color: lighten($primary-color, 10%);
  }
}

.text-primary-hover {
  color: $primary-color;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: lighten($primary-color, 10%);
  }
}

.text-secondary {
  color: $secondary-color;
}

.text-contrast {
  color: $contrast-color;
}

.text-info {
  color: $info-color;
}

.text-warning {
  color: $warning-color;
}

.text-gray3 {
  color: #333;
}

.text-gray6 {
  color: #666;
}

.text-gray9 {
  color: #999;
}

.text-white {
  color: $white;
}

.text-black {
  color: $black;
}

.text-weight {
  font-weight: 500;
}

/** font size **/
.f12 {
  font-size: 12px;
  line-height: 20px;
}

.f14 {
  font-size: 14px;
  line-height: 22px;
}

.f16 {
  font-size: 16px;
  line-height: 24px;
}

.f18 {
  font-size: 18px;
  line-height: 26px;
}

.f20 {
  font-size: 20px;
  line-height: 28px;
}

.f22 {
  font-size: 22px;
  line-height: 30px;
}

.f24 {
  font-size: 24px;
  line-height: 32px;
}

.f30 {
  font-size: 30px;
  line-height: 38px;
}

.f46 {
  font-size: 46px;
  line-height: 54px;
}

.f68 {
  font-size: 68px;
  line-height: 76px;
}

/** width **/
.w120 {
  width: 120px;
}

.w160 {
  width: 160px;
}

// margin
.mt5 {
  margin-top: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt40 {
  margin-top: 40px !important;
}
.mt60 {
  margin-top: 60px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr24 {
  margin-right: 24px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.mr50 {
  margin-right: 50px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}
.mb32 {
  margin-bottom: 32px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml30 {
  margin-left: 30px !important;
}
.ml40 {
  margin-left: 40px !important;
}
.ml50 {
  margin-left: 50px !important;
}

.ml120 {
  margin-left: 120px !important;
}

.gap10 {
  gap: 10px;
}

.gap15 {
  gap: 15px;
}

.gap20 {
  gap: 20px;
}

.gap30 {
  gap: 30px;
}

// display
.inline-block {
  display: inline-block !important;
  vertical-align: middle !important;
}

.text-underline {
  text-decoration: underline;
}

// width、height
.h100 {
  height: 100%;
}

.w100 {
  width: 100px;
}

.block {
  display: block;
}

//flex
.dis-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.row-flex {
  flex-direction: row;
}

.col-flex {
  flex-direction: column;
}

.flex-justify {
  justify-content: space-between;
}

.flex-around {
  justify-content: space-around;
}

.align-base {
  align-self: baseline;
}

.flex1 {
  flex: 1;
  flex-shrink: 0;
}

.nowrap {
  white-space: nowrap;
}

/** 通用页面样式 **/
.bg-white {
  padding: $paddingBase;
  border-radius: $radiusBase;
  background-color: $white;
}

.primary-shadow {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
}

.cursor {
  cursor: pointer;
}

.required {
  position: relative;
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
  }
}

.hidden {
  display: none !important;
}

.table-ope-btn {
  color: $primary-color;
  cursor: pointer;
}

.page-title {
  display: inline-flex;
  position: relative;
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 600;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background-color: rgba($primary-color, 0.2);
  }
}

//.scrollbar {
//  &::-webkit-scrollbar {
//    width: 5px;
//    height: 5px;
//  }
//  &::-webkit-scrollbar-thumb {
//    /*滚动条里面小方块*/
//    border-radius: 10px;
//    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
//    background: rgba(154, 159, 180, 0.7);
//  }
//  &::-webkit-scrollbar-track {
//    /*滚动条里面轨道*/
//    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
//    border-radius: 10px;
//    background: #ededed;
//  }
//}

.FormItem .error-detail {
  color: red;
  margin-left: 10px;
  font-size: 12px;
}

.FormItem .error-detail .ic-info2 {
  display: inline-block;
  margin-right: 4px;
  font-size: 16px;
  line-height: 1;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: middle;
  color: #ff6b6b;
}

.FormItem .desc {
  margin: 0 10px;
}

.FormItem .subtitle {
  margin-right: 4px;
  color: #333;
}

.FormItem .subtitle:empty {
  display: none;
}

.FormItem .dbDatePicker-wraper {
  display: inline-block;
}

.FormItem .dbDatePicker-wraper .to {
  margin: 0 5px;
}

.FormItem.checkbox-item .subtitle {
  display: none;
}

.FormItem .number-box {
  position: relative;
}

.FormItem .number-box .unit {
  position: absolute;
  line-height: 20px;
  right: 10px;
  top: calc(50% - 10px);
  color: #999;
}

.FormItem .wrapper-box {
  position: relative;
  display: inline-block;
}

.FormItem .wrapper-box input.limit {
  padding-right: 50px;
}

.FormItem .wrapper-box .length-limit {
  position: absolute;
  line-height: 20px;
  right: 6px;
  top: calc(50% - 10px);
  color: #ccc;
}

.FormItem .wrapper-box.TextArea .length-limit {
  top: auto;
  bottom: 5px;
}

.FormItem.textarea-item .length-limit {
  display: block;
  text-align: right;
  position: static;
  margin-top: 2px;
  font-size: 12px;
}

.resourceInfo {
  .resourceDetail {
    position: relative;
    margin-left: 80px;
    max-width: 360px;

    .resource-title {
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      /*! autoprefixer: off */
      -webkit-box-orient: vertical;
      word-break: break-all;
    }

    .w354 {
      width: 354px !important;
      padding-right: 58px;
    }

    > p {
      line-height: 1.2em;
    }
  }

  .edit-model {
    .subtitle {
      width: 56px;
      margin-right: 20px;
      vertical-align: top;
    }

    .resource-name-box {
      .subtitle {
        vertical-align: middle;
      }
    }

    .SelectCoverByScene {
      margin-bottom: 0;

      .material-desc {
        margin: 0;
        .marterial-desc-box {
          display: inline-block;
          width: 264px;
        }
      }
    }

    .btn-wrap {
      padding-left: 76px;
      text-align: left;

      .btn-gray {
        margin-top: 0;
      }
    }
  }
  // .Img-cover {
  //   background-color: transparent;
  // }
}

.resource-crumbs {
  display: flex;
  padding-bottom: 10px;
  li {
    color: #999;
    line-height: 20px;
    cursor: pointer;
    &:after {
      content: ">";
      margin: 0 5px;
    }
    &:last-child {
      color: #333;
      &:after {
        content: "";
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}
